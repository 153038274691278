<template>
  <div v-if="this.items.length > 0">
    <div class="product-list border-0 mb-5" v-for="(item, index) in this.items" :key="index">
      <h5>{{ item.name }}</h5>
      <div class="accordion indicator-plus-before round-indicator" role="tablist">
        <b-card no-body class="mb-1 qa" v-for="(qaItem, qaIndex) in (item.qa)" :key="qaIndex">
          <b-card-header collapsed header-tag="header" class="p-1" role="tab">
            <b-link block v-b-toggle="'accordion-' + qaIndex + index" variant="info" class="card-title">
              {{ qaItem.q }}
            </b-link>
          </b-card-header>
          <b-collapse :id="'accordion-' + qaIndex + index" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text v-html="qaItem.a"/>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Module5",
  props: {
    items: {type: Array, required: true},
  }
}
</script>

<style scoped>

</style>